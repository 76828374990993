<template>
  <v-container id="SiteDetailView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">
              {{ site.siteName ? site.siteName : "Site Detail" }}
            </div>
            <v-breadcrumbs
              class="px-0 py-2"
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
          </div>
          <v-btn small depressed color="primary" disabled> Save & Sync </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Site Information</span
        >
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          clearable
          label="Site Name"
          placeholder="Site Name"
          v-model="site.siteName"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          clearable
          label="Project Code"
          placeholder="Project Code"
          v-model="site.code"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          dense
          outlined
          clearable
          label="IoT Hub"
          item-text="name"
          item-value="_id"
          :items="hub_list"
          v-model="site.iotHubId"
        >
          <template v-slot:item="{ item }">
            <div class="py-3">
              {{ item.name }}
              <span class="grey--text text-lighten-1"
                >( {{ item.hubName }} )</span
              >
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium"> EMI Devices </span>
        <span class="text-caption grey--text"
          >( {{ site.properties.irrDeviceId.length }} devices )
        </span>
      </v-col>
      <v-col cols="12">
        <v-select
          multiple
          outlined
          color="primary"
          item-value="_id"
          :item-text="
            (item) => item.deviceName + ' ( ' + item.deviceType + ' )'
          "
          :items="device_list"
          v-model="site.properties.irrDeviceId"
        >
          <template v-slot:selection="{ attrs, item, selected }">
            <v-chip
              close
              color="primary"
              v-bind="attrs"
              :input-value="selected"
              @click:close="removeSelectedDeviceByID(item._id)"
            >
              {{ item.deviceName }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "SiteDetailView",
  data() {
    return {
      breadcrumbs: [
        {
          text: "Sites",
          exact: true,
          disabled: false,
          to: { name: "site" },
        },
        {
          text: "Create New Site",
          exact: true,
          disabled: true,
          to: { name: "site-create" },
        },
      ],
      site: {
        code: null,
        siteName: null,
        iotHubId: null,
        properties: {
          irrDeviceId: [],
        },
      },
    };
  },
  computed: {
    ...mapState({
      hub_list: (state) => state.hub.hub_list,
      device_list: (state) => state.device.device_list,
    }),
  },

  methods: {
    ...mapActions({
      requestGetHubList: "requestGetHubList",
      requestGetDeviceList: "requestGetDeviceList",
      requestGetSiteDetailByID: "requestGetSiteDetailByID",
    }),
    ...mapMutations({
      resetDeviceState: "RESET_DEVICE_STATE",
      setDeviceFilterLimit: "SET_DEVICE_FILTER_LIMIT",
      setDeviceFilterSiteID: "SET_DEVICE_FILTER_SITE_ID",
    }),
    removeSelectedDeviceByID(device_id) {
      console.log(device_id);
    },
  },
  async beforeMount() {
    const site_id = this.$route.params.id;
    // Call API : get hub list
    await this.requestGetHubList();
    // Call API : get device list by site id
    this.setDeviceFilterLimit(null);
    this.setDeviceFilterSiteID(site_id);
    await this.requestGetDeviceList();
    // Call API : get current site detail by id
    this.site = await this.requestGetSiteDetailByID(site_id);
    // Assign breadcrumbs text
    this.breadcrumbs[1].text = this.site.siteName;
  },
  beforeRouteLeave(to, from, next) {
    this.resetDeviceState();
    next();
  },
};
</script>
